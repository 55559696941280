import request from '../utils/request'
import baseUrl from './baseUrl'

export const shopownerList = (data) => request({
  url: baseUrl + '/distributionApply/selectForBack',
  method: 'POST',
  data
})
export const shopownerApply = (data) => request({
  url: baseUrl + '/distributionApply/modify',
  method: 'POST',
  data
})
export const shopownerList2 = (data) => request({
  url: baseUrl + '/userInfo/selectShopOwner',
  method: 'POST',
  data
})
export const modifyVipLevel = (data) => request({
  url: baseUrl + '/userInfo/modifyDisLevel',
  method: 'POST',
  data
})
export const allDistributionLevel = (data) => request({
  url: baseUrl + '/distributionLevel/selectAll',
  method: 'POST',
  data
})
export const shopOwnerList = (data) => request({
  url: baseUrl + '/distributionApply/selectForBack',
  method: 'POST',
  data
})
//修改店长标签
export const changeOwnerLabel = (data) => request({
  url: baseUrl + '/userInfo/modifyProfitLabel ',
  method: 'POST',
  data
})
//下载报表
export const downLoadList = (data) => request({
  url: baseUrl + '/userInfo/selectShopOwnerExport',
  method: 'POST',
  data,
 
  responseType: "blob",
  
})
//审核列表下载报表
export const createForm = (data) => request({
  url: baseUrl + '/distributionApply/exportExcel',
  method: 'POST',
  data,
  responseType: "blob",
})
//批量修改标签
export const setLabel = (data) => request({
  url: baseUrl + '/userInfo/modifyMoreProfitLabel',
  method: 'POST',
  data,
 
})
export const allSetLv = (data) => request({
  url: baseUrl + '/userInfo/modifyMoreProfitLevel',
  method: 'POST',
  data,
 
  
})
//导入表格
export const addExlex = (data) => request({
  url: baseUrl + '/userInfo/readExcel',
  method: 'POST',
  data,
 
  

})

