<template>
  <div class="productList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>审核列表</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="search_box">
      <span>申请店长姓名：</span>
      <el-input
        style="width: 200px"
        v-model="formData.trueName"
        placeholder="请输入会员姓名"
      />
      <span>申请店长手机号：</span>
      <el-input
        style="width: 200px"
        v-model="formData.phone"
        placeholder="请输入会员手机号"
      />
      <span>会员姓名：</span>
      <el-input
        style="width: 200px"
        v-model="formData.userName "
        placeholder="请输入会员姓名"
      />
     
      <span>会员手机号：</span>
      <el-input
        style="width: 200px"
        v-model="formData.userPhone "
        placeholder="请输入会员手机号"
      />
     
     
    </div>
    <div style=" margin-top:20px">
       <span >申请状态：</span>
      <el-select v-model="formData.status" placeholder="请选择申请状态">
        <el-option :key="-2" label="全部" :value="-2" />
        <el-option :key="0" label="申请中" :value="0" />
        <el-option :key="1" label="已通过" :value="1" />
        <el-option :key="2" label="未通过" :value="2" />
      </el-select>
      <el-button type="primary" class="btn" @click="getTableList()">查询</el-button>
      <el-button type="primary" class="btn" @click="createForm()">导出报表</el-button>
    </div>
    <el-table
      border
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="applyId" label="申请ID"> </el-table-column>
      <el-table-column prop="userName" label="会员姓名"> </el-table-column>
      <el-table-column prop="userPhone" label="会员手机号"> </el-table-column>
      <el-table-column prop="trueName" label="申请店长姓名"> </el-table-column>
      <el-table-column prop="phone" label="申请店长手机号"> </el-table-column>
      <el-table-column prop="idCard" label="身份证"> </el-table-column>
      <el-table-column prop="referrerInfo" label="推荐人信息">
      </el-table-column>
      <el-table-column prop="addTime" label="注册时间"> </el-table-column>
      <el-table-column prop="remark" label="备注信息"> </el-table-column>
      <el-table-column prop="strStatus" label="状态"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope" v-if="scope.row.status == 0">
          <el-button
            type="primary"
            size="mini"
            style="margin-left: 0.625rem"
            @click="showApply(scope.row)"
            v-if="
              $store.state.powerList.indexOf('distribution:apply:list:edit') !== -1">
            审核
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="formData.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="formData.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="formData.total"
    >
    </el-pagination>

    <el-dialog
      :close-on-click-modal="false"
      title="店长审核"
      :visible.sync="dialogVisible"
      width="50%"
    >
      <el-form ref="form" label-width="100px" class="grade_list">
        <el-form-item label="申请店长姓名">{{ editForm.trueName }}</el-form-item>
        <el-form-item label="申请店长手机号">{{ editForm.phone }}</el-form-item>
        <el-form-item label="身份证">{{ editForm.idCard }}</el-form-item>
        <el-form-item label="推荐人信息">{{
          editForm.referrerInfo
        }}</el-form-item>
        <el-form-item label="申请时间">{{ editForm.addTime }}</el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hideVisible()">取 消</el-button>
        <el-button type="primary" @click="dialogVisible2 = true"
          >不通过审核</el-button
        >
        <el-button type="primary" @click="dialogVisible3 = true"
        >审核通过</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title="不通过审核"
      :visible.sync="dialogVisible2"
      width="50%"
    >
      <el-form ref="form" label-width="100px" class="grade_list">
        <el-form-item label="不通过原因">
          <el-input
            type="textarea"
            :rows="4"
            v-model="editForm.remark"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hideVisible()">取 消</el-button>
        <el-button type="primary" @click="toApply(2)">审定</el-button>
      </span>
    </el-dialog>
    <!-- 通过原因 -->
    <el-dialog
      :close-on-click-modal="false"
      title="标签备注"
      :visible.sync="dialogVisible3"
      width="50%"
    >
      <el-form ref="form" label-width="100px" class="grade_list">
        <el-form-item label="标签备注">
          <el-input
            type="textarea"
            :rows="4"
            v-model="editForm.remark"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hideVisible()">取 消</el-button>
        <el-button type="primary" @click="toApply(1)">审定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { shopownerList, shopownerApply,createForm } from "../../api/wxShopowner.js";
import { sendFile } from "../../api/sendFile";
export default {
  name: "shopowner",
  data() {
    return {
      options: [],
      formData: {
        phone: "",
        trueName: "",
        status: -2,
        total: 0,
        pageSize: 10,
        currentPage: 1,
        userName:'',
        userPhone:''
      },
      editForm: {
        status: "",
        remark: "",
      },
      tableData: [],
      multipleSelection: [],
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3:false,
    };
  },
  created() {
    this.getTableList(); // 获取表格数据
  },

  methods: {
    async createForm(){
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
     let date = +new Date();
     const { data } = await createForm(this.formData);
     let url = window.URL.createObjectURL(data)//转换文件流为URL
     let link = document.createElement("a");
     link.style.display = "none";
     link.href = url;
     link.setAttribute("download", `万旅网小程序V2.0店长审核列表_${date}.xls`);
     document.body.appendChild(link);
     link.click();
     document.body.removeChild(link);
     loading.close()
    },
    async getTableList() {
      console.log("有没有");
      const { data } = await shopownerList(this.formData);
      this.tableData = data.list;
      this.formData.total = data.pagination.total;
      this.formData.pageSize = data.pagination.pageSize;
      this.formData.currentPage = data.pagination.current;
    },
    showApply(row) {
      this.editForm.applyId = row.applyId;
      this.editForm.phone = row.phone;
      this.editForm.idCard = row.idCard;
      this.editForm.addTime = row.addTime;
      this.editForm.trueName = row.trueName;
      this.editForm.referrerInfo = row.referrerInfo;
      this.dialogVisible = true;
    },
    async toApply(status) {
      let setData = {
        status,
      };
      this.dialogVisible3 = true
      if (status == 2 && this.editForm.remark == "") {
        return this.$message.error("请填写审核不通过原因");
      }
      setData.remark = this.editForm.remark;
      setData.applyId = this.editForm.applyId;

      const { data } = await shopownerApply(setData);
      console.log("编辑回调", data);
      if (data.code == 0) {
        this.hideVisible();
        this.getTableList();
        this.$message.success(data.msg);
        this.editForm.remark = ''
      } else {
        this.$message.error(data.msg);
      }
    },
    // 开始修改排序
    onChangeSort(row) {
      row.inputVisible = true;
      this.tableData = [...this.tableData];
    },
    // 修改排序
    async handleInputConfirm(row) {
      const { data } = await changeSort({
        materialId: row.materialId,
        dept: Number(row.dept),
      });
      if (data.code !== 0) {
        this.$message({
          message: "设置失败",
          type: "error",
        });
      } else {
        this.$message({
          message: "设置成功",
          type: "success",
        });
      }
      row.inputVisible = false;
    },
    // 隐藏弹窗
    hideVisible() {
      this.dialogVisible = false;
      this.dialogVisible2 = false;
      this.dialogVisible3 = false;
    },
    handleSelectionChange(arr) {
      this.multipleSelection = arr;
      console.log(this.multipleSelection);
    },
    handleSizeChange(num) {
      console.log(num);
      this.formData.pageSize = num;
      this.getTableList();
    },
    handleCurrentChange(num) {
      console.log("currentPage", num);
      this.formData.currentPage = num;
      this.getTableList();
    },
    onInput(value) {
      var reg = /^(0\.?\d{0,5}|[1-9]\d*\.?\d{0,5})$/;
      if (!reg.test(value)) {
        return this.$message({
          type: "error",
          message: "请输入正确的数字!",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.productList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .add {
    margin-top: 20px;
  }
  .el-table {
    margin-top: 50px;
  }
  .search_box {
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .btn {
      margin: 0 20px;
    }
    span {
      display: inline-block;
      margin-left: 20px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  /deep/.el-dialog__header {
    border-bottom: 1px solid #f1f1f1;
  }
  /deep/.el-dialog__footer {
    border-top: 1px solid #f1f1f1;
  }
  .btn_box {
    margin-top: 24px;
  }
  .modify_btn {
    padding-left: 10px;
    padding-right: 10px;
  }
  /deep/.popconfirm {
    margin-right: 10px;
  }
}
</style>